import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

export const fetchUserdetails = createAsyncThunk(
    'userDetails/fetch',
    async ({ uuid, phoneNumber, email }) => {
        const maxRetries = 3;
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axios.post('https://ninjacurate.learntube.ai/user_details', { uid: uuid, phone_number: phoneNumber, email: email });
                if (response.data === true || response.data === false) {
                    window.open('https://custom.learntube.ai')
                    return;
                }
                return response.data;
            }
            catch (err) {
                console.log(err);
                retries += 1;
            }
        }
        toast.error('Error in fetching user details. Please try again later.');
});

const initialState = {
    details: {
        uid: '',
        phone_number: '',
        email: ''
    }
}
// Redux slice for questions
const useDetailsSlice = createSlice({
    name: 'userDeatils',
    initialState,
    reducers: {
        setUserDetailsFromOnboarding: (state, action) => {
            const { uuid, phoneNumber, email } = action.payload
            state.details.email = email
            state.details.phone_number = phoneNumber
            state.details.uid = uuid
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserdetails.fulfilled, (state, action) => {
            return action.payload;
        });
    },
});

export const { setUserDetailsFromOnboarding } = useDetailsSlice.actions
export const selectUserDetails = (state) => state.userDetails;
export default useDetailsSlice.reducer;
