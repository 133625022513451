import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit"
import axios from "axios"

export const fetchInfluencerDetail = createAsyncThunk(
    'fetch-influencer-detail',
    async (creator_slug) => {
        try {
            const response = await axios.get(`https://xgfy-czuw-092q.m2.xano.io/api:ZZCtun0K/fetch_creator_courses?creator_slug=${creator_slug}`)
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

const initialState = {
    loading: false,
    influencerDetail: {}
}

const influencerSlice = createSlice({
    name: 'influencerDetail',
    initialState,
    reducers: {
        resetInfluencerData: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchInfluencerDetail.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchInfluencerDetail.fulfilled, (state, action) => {
            state.influencerDetail = action.payload
            state.loading = false
        })
    }
})

export const { resetInfluencerData } = influencerSlice.actions
export const selectInfluencerDetail = (state) => state.influencerDetails?.influencerDetail
export default influencerSlice.reducer