import React, { useCallback, useEffect, useState } from 'react'
import LogosVersion2 from './Logo2'
import { useDispatch, useSelector } from 'react-redux'
import { fetchToken, logoutUser, selectAuthentication, setAuthenticationError, setUserEmail } from '../redux/authenticationSlice'
import { ClipLoader, SyncLoader } from 'react-spinners';
import { useLocation, useNavigate } from 'react-router-dom'
import { Mixpanel } from '../config/Mixpanel';
import constants from '../utilities/constants';
import { fetchCourseList } from '../redux/courseListSlice';

const Welcome = () => {

    const userEmail = useSelector(selectAuthentication)?.email
    const [ email, setEmail ] = useState(userEmail)
    const [ loading, setLoading ] = useState(false)
    const [ welcomePageLoading, setWelcomePageLoading ] = useState(false)
        
    const error = useSelector(selectAuthentication)?.error
    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const params = new URLSearchParams(location.search)
    const emailID = params.get('email')

    const newLearners = useCallback(() => {
        const time = new Date()
        const hours = time.getHours()
        const minutes = time.getMinutes()
        let maxLearners = hours.toString() + (minutes < 9 ? '0' + minutes.toString() : minutes.toString())
        return Math.max(parseInt(maxLearners)+9, 171)
    }, [])


    useEffect(() => {
        dispatch(logoutUser())
        Mixpanel.track(constants.Mixpanel.WELCOME_PAGE)
        if (emailID){
            setWelcomePageLoading(true)
            const fetchData = async () => {
                dispatch(setUserEmail(emailID))
                try {
                    const response = await dispatch(fetchToken({ email_id: emailID }))
                    if (!response?.payload?.Error){
                        const courseResponse = await dispatch(fetchCourseList({ uid: response?.payload?.uid }))
                        navigate(`/${courseResponse?.payload?.subject_names?.[0]}`)
                    }
                }catch (err) {
                    console.log(err.message);
                }finally{
                    setWelcomePageLoading(false)
                }
            }
            fetchData()
        }
    }, [dispatch, emailID, navigate])

    const handleSubmit = (e) => {
        e.preventDefault()
        Mixpanel.track(constants.Mixpanel.WELCOME_PAGE_BUTTON_CLICKED)
        setLoading(true)
        if (error) {
            setLoading(false)
            dispatch(setAuthenticationError(null))
            dispatch(setUserEmail(null))
            navigate('/')
        } else {
            const fetchData = async () => {
                dispatch(setUserEmail(email))
                try {
                    const response = await dispatch(fetchToken({ email_id: email }))
                    console.log(response);
                    if (!response?.payload?.Error){
                        const courseResponse = await dispatch(fetchCourseList({ uid: response?.payload?.uid }))
                        navigate(`/${courseResponse?.payload?.subject_names?.[0]}`)
                    }
                }catch (err) {
                    console.log(err.message);
                }finally{
                    setLoading(false)
                }
            }
            fetchData()
        }
    }

    const handleInputChange = (e) => {
        if (error) dispatch(setAuthenticationError(null))
        setEmail(e.target.value)
    }

    return (
        <div className='mx-auto h-[100dvh] flex flex-col overflow-y-scroll' style={{background: 'radial-gradient(50% 50% at 50% 50%, #26496A 0%, #182C3F 100%)'}}>
            {!welcomePageLoading ? <div className='p-4 flex-1 text-white flex flex-col justify-between max-w-[720px] mx-auto'>
                <div className='flex justify-center'>
                    <LogosVersion2/>
                </div>
                <div className='flex flex-col h-full justify-center w-full'>
                    <div className='text-center'>
                        <h2 className='text-[38px] leading-[37px] font-bold text-center mt-10'>Continue Learning &   <span className='text-[#00C1FF]'>3x Your Career</span></h2>
                        <p className='text-center leading-[19px] my-3 font-medium'><span className='opacity-50'>Fast-forward your</span> <span className='opacity-80'>future</span><span className='opacity-50'> with our magical AI powered </span><span className='opacity-80'>hyper-personalised learning!</span></p>
                    </div>
                    <form onSubmit={handleSubmit} className='flex-1 flex flex-col justify-center'>
                        <label htmlFor="email" className='text-2xl font-bold'>What’s your email?</label>
                        <input 
                            type="text" 
                            id='email'
                            value={email}
                            onChange={handleInputChange}
                            className={`w-full bg-[#2D4F71] h-[46px] focus:bg-[#2D4F71] active:bg-[#2D4F71] rounded-xl py-2.5 placeholder:text-[#6D90B4] mt-3 ${error ? 'text-[#E06064] welcome border-[#E06064]' : 'text-white name'}`}
                            placeholder='Input the id you used to make your course'
                        />
                        {error ?  <p className='text-sm text-[#E06064] leading-[17px] mt-2'>Email not found. Try again or create a new course.</p> : null}
                        <button disabled={!email} type='submit' className='w-1/2 md:w-1/3 h-[45px] flex items-center justify-center bg-[#7FC241] py-3 px-2 rounded-xl mt-4 disabled:opacity-50 font-semibold text-black '>{loading ? <ClipLoader size={20}/> : error ? 'Create Course' : 'Continue Learning'}</button>
                    </form>
                </div>
                <div className='flex-1 h-full flex flex-col items-center'>
                    <div className='flex items-center'>
                        <div className='w-24 h-full'>
                            <img src="https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Group_8604.png?updatedAt=1687502602718" alt="" className='w-full h-auto'/>
                        </div>
                        <p className='ml-2 text-sm'>
                            Join the <span className='text-[#00C1FF] font-bold'>{newLearners()} users</span> learning today
                        </p>
                    </div>
                </div>
            </div> : 
            <div className='flex-1 flex flex-col items-center p-4'>
                <LogosVersion2/>
                <div className='h-full flex items-center justify-center flex-1'>
                    <SyncLoader color='#7FC241'/>
                </div>
            </div>}
        </div>
    )
}

export default Welcome