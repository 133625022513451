/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useRef} from 'react'
import { faArrowRight, faArrowTurnDown, faCheck, faChevronLeft,  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { animateScroll } from 'react-scroll'
import { useDispatch } from 'react-redux'
import constants, { allPopularSkills, capitalise, options, popularSkillsFromCategories } from '../../utilities/constants'
import { setSavedResponse, setShowCourseLoader, setUTMDetails, setUserSkillDetails, setVisitedAnalysisPage } from '../../redux/skillSlice'
import { toast } from 'react-toastify'
import { Mixpanel } from '../../config/Mixpanel'
import useWindowSize from '../../hooks/useWindowSize'
import { resetAnalysis } from '../../redux/analysisSlice'
import { resetQuestionAnswer } from '../../redux/questionAnswerSlice'
import { resetProjectsData } from '../../redux/projectSlice'
import { resetCourseData } from '../../redux/coursesSlice'
import { resetXanoData } from '../../redux/beforePaymentSlice'
import { setAnalysisPageButtonClicked, setLastVisitedUrl } from '../../redux/nameSlice'
import { resetCurationStatus } from '../../redux/curatedCourseSlice'
import { resetQuestions } from '../../redux/questionsSlice'
import { resetSavedDetails } from '../../redux/savedCourseSlice'
import { fetchPopularSkills } from '../../redux/searchPopularSkillSlice'
import useDebounce from '../../hooks/useDebounce'
import SearchSkeleton from '../../components/SearchSkeleton'
import { fetchInfluencerDetail, resetInfluencerData } from '../../redux/influencerDetailsSlice'
import { setSource } from '../../redux/CourseAndSkillListSlice'


const LandingPageV2= () => {

    
    const location = useLocation()

    const params = new URLSearchParams(location.search)
    const utm_source = params.get('utm_source')
    const utm_campaign = params.get('utm_campaign')
    const utm_medium = params.get('utm_medium')
    const skill_slug = params.get('skill_slug')
    const course_slug = params.get('course_slug')
    const source = params.get('source')

    const inputRef = useRef(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { height } = useWindowSize()
    
    const [goalValue, setGoalValue] = useState(source === 'academy' ? skill_slug : '')
    const [popularSkills, setPopularSkills] = useState([])
    const [selectedSkill, setSelectedSkill] = useState([])
    const [ searchResult, setSearchResult ] = useState([])
    const [ scrollToTop, setScrollToTop] = useState(false)
    const [showTitle, setShowTitle] = useState(true)
    const [step, setStep] = useState((source === 'academy' && skill_slug) ? 2 : 1)
    const { debouncedValue } = useDebounce(goalValue)
    
    console.log("path name", location.pathname)
    useEffect(()=>{
        Mixpanel.track(constants.Mixpanel.LANDING_PAGE)

        localStorage.setItem('showScholarship', JSON.stringify({}))
        localStorage.setItem('visitedAnalysisPage', JSON.stringify(false))
        dispatch(resetInfluencerData())
        dispatch(resetQuestions())
        dispatch(resetAnalysis())
        dispatch(resetQuestionAnswer())
        dispatch(resetProjectsData())
        dispatch(resetCourseData())
        dispatch(resetXanoData())
        dispatch(setAnalysisPageButtonClicked(false))
        dispatch(resetCurationStatus())
        dispatch(resetSavedDetails())
        dispatch(setShowCourseLoader(false))
        dispatch(setVisitedAnalysisPage(false))
        dispatch(setSavedResponse(false))
        dispatch(setUTMDetails({ utm_source: utm_source ? utm_source : "", utm_medium: utm_medium ? utm_medium : "", utm_campaign: utm_campaign ? utm_campaign : "" }))
        if (source === 'academy'){
            dispatch(setSource(source))
            dispatch(fetchInfluencerDetail(utm_source))
        }
        dispatch(setLastVisitedUrl('/'))
        if (skill_slug){
            const fetchData = async ()=> {
                try {
                    await setGoalValue(skill_slug)
                    const res = await dispatch(fetchPopularSkills({ searchValue: skill_slug}))
                    setPopularSkills(res?.payload)
                } catch (error) {
                    console.log(error)
                }
            }
            fetchData()
        }

        if (course_slug){
            setPopularSkills(popularSkillsFromCategories.hasOwnProperty(course_slug) ? popularSkillsFromCategories[course_slug]?.skills : allPopularSkills)
            setSelectedSkill(Array(popularSkillsFromCategories.hasOwnProperty(course_slug) ? popularSkillsFromCategories[course_slug]?.skills?.length : allPopularSkills.length).fill(false))
        }

        if (!skill_slug && !course_slug){
            if(inputRef.current){
                inputRef.current.focus()
            }
            setPopularSkills(allPopularSkills)
        }
        
    }, [])

    useEffect(() => {
        if(!showTitle && goalValue.length > 0 && !scrollToTop){
            setScrollToTop(true)
        }
    }, [goalValue, showTitle])

    useEffect(() => {
        if(scrollToTop){
            animateScroll.scrollMore(height <= 740 ? 80 : 250, { duration: 300 })
        }else{
            animateScroll.scrollToTop({ duration: 300 })
        }
    }, [scrollToTop])

    useEffect(() => {
        if(debouncedValue){
            const fetchData = async() => {
                try {
                    const res = await dispatch(fetchPopularSkills({ searchValue: debouncedValue }))
                    if(res?.payload?.length > 0){
                        setSearchResult(res?.payload)
                    }else{
                        setSearchResult([debouncedValue])
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            fetchData()
        }else{
            setSearchResult([])
        }
    }, [debouncedValue])

    useEffect(() => {
        if(step === 2){
            animateScroll.scrollToTop({ duration: 300 })
        }
    }, [step])
    
    const handleSubmit = (e) => {
        e.stopPropagation()
        e.preventDefault()
        if (goalValue === '') {
            toast.error('Type a skill you want to learn')
            return
        }
        Mixpanel.people.set({
            'skillName': capitalise(goalValue)
        })
        Mixpanel.track(constants.Mixpanel.SKILL_NAME)
        setStep(2)
    }

    const handleChange = (option) => {
        Mixpanel.track(option.description === 'Almost 0 knowledge' ? constants.Mixpanel.SKILL_LEVEL1 : option.description === 'Basic understanding' ? constants.Mixpanel.SKILL_LEVEL2 : option.description === 'Have done some work on it' ? constants.Mixpanel.SKILL_LEVEL3 : constants.Mixpanel.SKILL_LEVEL4)
        Mixpanel.people.set({
            'skillLevel': option.skill
        })
        let value = capitalise(goalValue)
        dispatch(setUserSkillDetails({ skillName: value, skillLevel: option.skill, skillDescription: option.description, utm_source: utm_source ? utm_source : "", utm_medium: utm_medium ? utm_medium : "", utm_campaign: utm_campaign ? utm_campaign : ""}))
        navigate('/onboarding')
    }

    const handleSkillSelection = (index) => {
        setShowTitle(true)
        setScrollToTop(false)
        const tempArray = [...Array(selectedSkill.length).fill(false)]
        tempArray[index] = true
        setSelectedSkill(tempArray)
        setGoalValue(popularSkills[index])
    }

    const handleDropResults = (index) => {
        if (inputRef.current){
            inputRef.current.focus()
        }
        setShowTitle(false)
        setGoalValue(searchResult[index])
    }

return (
    <>
        {step === 1 && <div className='max-w-[720px] flex-1 mx-auto px-4 pb-4 lg:pb-6 lg:px-6 text-white relative text-center flex flex-col gap-2' onClick={(e) => {
            setShowTitle(true)
            setScrollToTop(false)
        }}>
            <div className=''>
                <h1 className={`text-[38px] lg:text-[44px] ${height <= 740 ? 'pt-3' : 'pt-8'} pb-2 leading-9`}>Achieve Your Goals <span className='text-[#00C1FF] font-bold'>3x Faster</span></h1>
                <p className='lg:text-2xl opacity-80 leading-4'>Analyse your skills & get the best {(course_slug && popularSkillsFromCategories.hasOwnProperty(course_slug)) ? popularSkillsFromCategories[course_slug]?.name : skill_slug ? skill_slug : "" } course personalised just for you!</p>
            </div>
            <div className={`flex-1 flex flex-col justify-center`}>
                <h2 className='text-[22px] lg:text-[24px] text-left font-bold'>What course do you want?</h2>
                <form onSubmit={handleSubmit}>
                    <div className='relative'>
                        <input type="text" 
                            ref={inputRef}
                            className='bg-[#2D4F71] text-white rounded-lg py-2 h-[44px] pl-4 pr-12 mt-2 w-full mx-auto user_goal box-glow'
                            placeholder='Type any topic you want to learn'
                            value={goalValue}
                            onFocus={() => {
                                setShowTitle(false)
                            }}
                            onChange={(e) => {
                                if (showTitle) {
                                    setShowTitle(false)
                                }
                                setGoalValue(e.target.value)
                            }}
                        />
                        {goalValue && <FontAwesomeIcon icon={faArrowTurnDown} onClick={handleSubmit} className='text-[#7FC241] text-xl absolute right-5 z-10 bottom-1.5 rotate-90 cursor-pointer'/>}
                    </div>
                    {(goalValue && showTitle) && <div className='flex justify-between items-center mt-5'>
                        <button className={`bg-[#7FC241] h-[44px] flex items-center justify-center text-black font-semibold rounded-xl py-2 px-4 w-1/2 lg:w-1/3 cursor-pointer`}>Get Started <span><FontAwesomeIcon icon={faArrowRight} className='text-xl ml-4 mt-1 arrow-move'/></span></button>
                        <p className=' text-sm tracking-wide font-normal opacity-70'>Takes just 5 min</p>
                    </div>}
                </form>
                {(!showTitle && goalValue) && <div className=' mt-4 rounded-lg' 
                    style={{ boxShadow: '0px 0px 7px 0px #7FC241' }}
                >
                    {searchResult.length <= 0 ? <div className='h-[226px] w-full rounded-lg'>
                        <SearchSkeleton/>
                    </div> : <div className={` ${searchResult?.length > 5 ? 'takeaway' : ''} rounded-lg border border-[#7FC241] bg-[#2D4F71] h-[226px] overflow-y-scroll`} >
                        {searchResult?.map((item, index) => (
                            <div key={index} onClick={() => handleDropResults(index)} className={`text-left ${goalValue === item ? 'bg-[#2B71BA]' : '' } px-3 py-2.5 border-b border-[#3572AF]`}>
                                {item}
                            </div>
                        ))}
                    </div>}
                </div>}
                {(showTitle || !goalValue) && <>
                    <div className={`flex justify-between items-center ${height <= 740 ? 'mt-4' : 'mt-8'} mb-2 text-[#90BEED]`}>
                        <p>Popular Topics</p>
                        <Link to='/course-page'><p className='underline text-xs'>Explore By Category?</p></Link>
                    </div>
                    <div className={`${(height <= 740 && goalValue) ? 'h-[85px]' : height <= 740 ? 'h-[128px]' : goalValue ? 'h-[128px]' : 'h-[170px]'} overflow-y-scroll ${popularSkills?.length < 3 ? '' : 'takeaway'}`}>
                        <div className='flex gap-2 flex-wrap'>
                            {popularSkills.map((item, index) => (
                                <div key={index} className={`${goalValue === item ? 'bg-[#316EAB] border border-[#7FC241]' : 'bg-[#2D4F71] text-[#9ABFE5]'} py-2 px-3 cursor-pointer rounded-lg text-sm h-fit`} onClick={(e) => {
                                    e.stopPropagation()
                                    handleSkillSelection(index)
                                }}>
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>
                </>}
            </div>
            <div className='w-full lg:w-3/5 mx-auto'>
                <p className='font-semibold tracking-wide text-base mb-0.5'>We Build Life-Changing Careers</p>
                <div className='bg-[#2D4F71] flex justify-between py-3.5 px-5 rounded-xl'>
                    <div className='text-center'>
                        <h2 className='text-2xl font-bold'>10<span className='text-sm font-medium'>Lakh+</span></h2>
                        <p className='text-xs text-[#9ABFE5] font-medium'>Active Users</p>
                    </div>
                    <div className='text-center'>
                        <h2 className='text-2xl font-bold'>4.8/5</h2>
                        <p className='text-xs font-medium text-[#9ABFE5]'>Course Rating</p>
                    </div>
                    <div className='text-center'>
                        <h2 className='text-2xl font-bold'>34<span className='text-sm font-medium'>Lakh</span></h2>
                        <p className='text-xs font-medium text-[#9ABFE5]'>Highest Package</p>
                    </div>
                </div>
            </div>
        </div>}
        {step === 2 &&
            <div className='text-center max-w-[1440px] text-white flex-1 p-4 lg:pb-6 lg:px-10 relative flex flex-col lg:flex-row lg:items-center lg:justify-between lg:gap-5 mx-auto'>
                {source !== 'academy' && <FontAwesomeIcon
                    icon={faChevronLeft}
                    className='absolute z-[999] -top-12 left-4 lg:left-12 text-2xl lg:text-3xl cursor-pointer'
                    onClick={() => setStep(1)}
                />}
                <div className='lg:flex-1'>
                    <div className={`${height <= 720 ? 'w-[80%]' : 'w-full'} md:w-[50%] h-auto mx-auto pl-2`}>
                        <img src="https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/online-course_1.png?updatedAt=1685205758186" width={320} height={213} alt="" className='w-full h-full'/>
                    </div>
                    <h2 className={`${height <= 720 ? 'text-xl lg:text-[44px]' : 'text-2xl lg:text-[44px]'} leading-6 lg:leading-[50px]`}>Let’s Create The Best <span className='font-bold text-[#00C1FF]'>{capitalise(goalValue)} Course</span> For You!</h2>
                </div>
                <div className='flex-1 flex-shrink-0 flex flex-col justify-center lg:justify-start pb-10 lg:pb-0'>
                    <h2 className={`${height <= 720 ? 'text-xl mt-5 lg:text-2xl' : 'text-2xl'} leading-6 font-bold text-white text-left mb-3`}>What’s your current skill level?</h2>
                    <div className='flex flex-col gap-2.5'>
                        {options.map((option) => (
                            <div className={`cursor-pointer w-full h-[45px] md:h-[64px] flex justify-between items-center rounded-xl hover:bg-[#517EAA] bg-[#2D4F71]`} key={option.id} onClick={() => handleChange(option)}>
                                <div className='flex items-center'>
                                    <div className='w-4 md:w-6 h-4 md:h-6 mx-3 border md:border-2 border-[#7FC241] rounded-full flex justify-center items-center'>
                                        <div className={`w-2 md:w-3 h-2 md:h-3 rounded-full`}/>
                                    </div>
                                    <p className={`${height <= 720 ? 'text-lg lg:text-xl' : 'text-xl'} font-medium`}>{option.description}</p>
                                </div>
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    className={`text-[#DBFF00] text-xl mr-4 font-black hidden`}
                                />
                            </div>
                        ))}   
                    </div>
                </div>
            </div>
        }
    </>
  )
}

export default LandingPageV2