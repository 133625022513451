import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const fetchCurationStatus = createAsyncThunk(
    'postPayment/fetchCurationStatus',
    async ({ user_name, user_uuid, course_name, skill_difficulty, user_email, is_curation, new_uuid }) => { 
        let retries = 0
        while (retries < 3){     
            try {
                const response = await axios.post('https://ninjacurate.learntube.ai/course_after_paid', 
                {
                    user_name: user_name, 
                    user_uuid: user_uuid, 
                    course_name: course_name, 
                    skill_difficulty: skill_difficulty, 
                    user_email: user_email, 
                    is_curation: is_curation,
                    new_uuid: new_uuid
                });
                if (response.status === 200){
                    return response.data
                }
            } catch (err) {
                retries += 1
                console.log(err);
            }
        }
  }
);

const postPaymentSlice = createSlice({
    name: 'postPayment',
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchCurationStatus.fulfilled, (state, action) => {
            return action.payload
        })
    },
});

export const selectCurationStatus = (state) => state.postPayment;
export default postPaymentSlice.reducer;