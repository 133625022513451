import React, { Suspense, useEffect, useLayoutEffect } from 'react'
import { Routes, Route, useLocation} from 'react-router-dom'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Loader from './components/Loader'
import Layout from './pages/Layouts/Layout'
import LayoutTwo from './pages/Layouts/LayoutTwo'
import Welcome from './components/Welcome'
import PrivateRoute from './config/private-route'
import LandingPageV2 from './pages/LandingPage/LandingPageV2'
import { animateScroll } from 'react-scroll'
import TagManager from 'react-gtm-module'
import * as Sentry from "@sentry/react";
import FallbackUI from './components/FallbackUI'


const SkillList = React.lazy(() => import('./pages/LandingPage/SkillList'))
const CourseList = React.lazy(() => import('./pages/LandingPage/CourseList'))
const OnBoardingPage = React.lazy(() => import(/* webpackPrefetch: true */ './pages/OnBoarding'))
const EvenUserLoader = React.lazy(() => import('./components/EvenUserLoader'))
const MCQCurationLoader = React.lazy(() => import('./components/MCQCurationLoader'))
const MCQCourse = React.lazy(() => import('./pages/MCQCourse'))
const MCQAnalysis = React.lazy(() => import('./pages/MCQAnalysis'))
const OnboardingLoader = React.lazy(() => import('./components/OnboardingLoader'))
const QuestionarySection = React.lazy(() => import('./pages/QuestionarySection'))
const MCQuestionsPage = React.lazy(() => import('./pages/MCQuestionsPage'))
const Analysis = React.lazy(() => import('./pages/Analysis'))
const Register = React.lazy(()=> import(/* webpackPrefetch: true */ './pages/Register'))
const CourseSection = React.lazy(() => import(/* webpackPrefetch: true */ './pages/CourseSection'))
const SavedCourseSection = React.lazy(()=> import('./pages/SavedCoursePage'))
const PricingVersion2 = React.lazy(()=> import(/* webpackPrefetch: true */ './pages/PricingVersion2'))
const ThankYou = React.lazy(() => import('./pages/Pricing/ThankYou'))
const PageNotFound = React.lazy(() => import('./components/PageNotFound'))
const LessonPage = React.lazy(() => import('./pages/Lessons'))
const ProjectsPage = React.lazy(() => import('./pages/Projects'))
const CourseListPage = React.lazy(() => import('./pages/CourseList'))
const CurationLoader = React.lazy(() => import('./components/CurationLoader'))

const tagManagerArgs = {
    gtmId: 'GTM-WFR8KQ7'
}

function App() {

    const { pathname } = useLocation()

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
    }, [])

    useLayoutEffect(()=>{
        animateScroll.scrollToTop({ duration: 300 })
    }, [pathname])


    return (
        <Sentry.ErrorBoundary fallback={({ resetError }) => <FallbackUI resetError={resetError}/>}>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<LandingPageV2/>} />
                    <Route path='course-page' element={<Suspense fallback={<Loader/>}><CourseList/></Suspense>} />
                    <Route path='skill-page' element={<Suspense fallback={<Loader/>}><SkillList/></Suspense>} />
                    <Route path='onboarding'>
                        <Route index element={<Suspense fallback={<Loader/>}><OnBoardingPage/></Suspense>} />
                        <Route path='loader-1' element={<Suspense fallback={<Loader/>}><OnboardingLoader/></Suspense>}/>
                        <Route path='questions/:id' element={<Suspense fallback={<Loader/>}><QuestionarySection/></Suspense>}/>
                        <Route path='mcquestions/:id' element={<Suspense fallback={<Loader/>}><MCQuestionsPage/></Suspense>}/>
                        <Route path='register' element={<Suspense fallback={<Loader/>}><Register/></Suspense>}/>
                        <Route path='mcqanalysis' element={<Suspense fallback={<Loader/>}><MCQAnalysis/></Suspense>}/>
                        <Route path='courses' element={<Suspense fallback={<Loader/>}><MCQCourse/></Suspense>}/>
                        <Route path='loader' element={<Suspense fallback={<Loader/>}><MCQCurationLoader/></Suspense>}/>
                        <Route path='loader-2' element={<Suspense fallback={<Loader/>}><EvenUserLoader/></Suspense>}/>
                    </Route>
                    <Route path='analysis' element={<Suspense fallback={<Loader/>}><Analysis/></Suspense>}/>
                    <Route path='courses' element={<Suspense fallback={<Loader/>}><CourseSection /></Suspense>} />
                    <Route path='saved-courses' element={<Suspense fallback={<Loader/>}><SavedCourseSection /></Suspense>} />
                    <Route path= 'pricing'>
                        <Route path='v2' element={<Suspense fallback={<Loader/>}><PricingVersion2/></Suspense>}/>
                        <Route path='loading' element={<Suspense fallback={<Loader/>}><CurationLoader/></Suspense>}/>
                        <Route path='thank-you' element={<Suspense fallback={<Loader/>}><ThankYou /></Suspense>} />
                    </Route>
                </Route>
                <Route path='welcome' element={<Welcome/>}/>
                <Route path='/' element={<LayoutTwo/>}>
                    <Route path=':course_slug' element={<PrivateRoute><Suspense fallback={<Loader/>}><CourseListPage/></Suspense></PrivateRoute>}/>
                    <Route path=':course_slug/:module_slug/:lesson_slug' element={<PrivateRoute><Suspense fallback={<Loader/>}><LessonPage/></Suspense></PrivateRoute>}/>
                    <Route path=':course_slug/:project' element={<PrivateRoute><Suspense fallback={<Loader/>}><ProjectsPage/></Suspense></PrivateRoute>}/>
                    <Route path= '*' element={<Suspense fallback={<Loader/>}><PageNotFound/></Suspense>} />
                </Route>
            </Routes>
        </Sentry.ErrorBoundary>
    )
}

export default App;
