import { REK, ENV } from './config/api_config';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { persistReducer } from 'redux-persist';
import skillReducer from './redux/skillSlice';
import questionsReducer from './redux/questionsSlice';
import analysisReducer from './redux/analysisSlice';
import nameReducer from './redux/nameSlice';
import userDetailsReducer from './redux/userDetailsSlice';
import coursesReducer from './redux/coursesSlice';
import projectReducer from './redux/projectSlice';
import keyTakeawaysReducer from './redux/keyTakeawaySlice';
import lessonQuizReducer from './redux/lessonQuizSlice';
import feedbackReducer from './redux/feedbackSlice';
import askCreatorReducer from './redux/askCreatorSlice';
import courseListReducer from './redux/courseListSlice';
import courseModuleReducer from './redux/courseModuleSlice';
import progressReducer from './redux/moduleProgressSlice';
import lessonDetailsReducer from './redux/lessonDetailsSlice';
import scholarshipDetailsReducer from './redux/scholarshipAppliedSlice';
import authenticationReducer from './redux/authenticationSlice';
import postPaymentReducer from './redux/postPaymentSlice'
import beforePaymentReducer from './redux/beforePaymentSlice'
import questionAnswersReducer from './redux/questionAnswerSlice';
import timerReducer from './redux/timerSlice'
import curatedCourseReducer from './redux/curatedCourseSlice';
import savedCourseReducer from './redux/savedCourseSlice';
import successStoriesAndReviewsReducer from './redux/successStoriesAndReviewSlice';
import mcQuestionsReducer from './redux/MCQSlice'
import subscriptionStatusReducer from './redux/subscriptionStatusSlice';
import walkthroughReducer from './redux/walkthrough'
import bottomIllustrationReducer from './redux/bottomIllustration';
import userDetailsFromOnboardingReducer from './redux/userDetailsFromOnboardingSlice';
import CourseAndSkillListReducer from './redux/CourseAndSkillListSlice';
import influencerDetailsReducer from './redux/influencerDetailsSlice';

const isDevelopment = ENV === 'DEVELOPMENT';

const encryptor = encryptTransform({
    secretKey: REK,
    onError: function (error) {
      // Handle the error.
      console.log(error)
    }
})

const persistConfig = {
    key: "root",
    transforms: [encryptor],
    version: 1,
    storage
};

const reducer = combineReducers({
    skillDetails: skillReducer,
    questions: questionsReducer,
    analysis: analysisReducer,
    name: nameReducer,
    userDetails: userDetailsReducer,
    courses: coursesReducer,
    projects: projectReducer,
    keyTakeaway: keyTakeawaysReducer,
    lessonQuiz: lessonQuizReducer,
    feedback: feedbackReducer,
    askCreator: askCreatorReducer,
    courseList: courseListReducer,
    courseModule: courseModuleReducer,
    progress: progressReducer,
    lessonDetails: lessonDetailsReducer,
    scholarshipDetails: scholarshipDetailsReducer,
    authentication: authenticationReducer,
    postPayment: postPaymentReducer,
    beforePayment: beforePaymentReducer,
    questionAnswers: questionAnswersReducer,
    curatedCourse: curatedCourseReducer,
    timer: timerReducer,
    savedCourse: savedCourseReducer,
    successStoriesAndReviews: successStoriesAndReviewsReducer,
    mcQuestions: mcQuestionsReducer,
    subscriptionStatus: subscriptionStatusReducer,
    walkthrough: walkthroughReducer,
    bottomIllustration: bottomIllustrationReducer,
    userDetailsFromOnboarding: userDetailsFromOnboardingReducer,
    courseAndSkill: CourseAndSkillListReducer,
    influencerDetails: influencerDetailsReducer
})

const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunkMiddleware],
    devTools: isDevelopment,
});

 
export default store;